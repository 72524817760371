$max-width: 1200px;
// $max-width: 1024px;
$font-regular: 'Roboto', sans-serif;
$font-headline: 'Amatic SC', cursive;
$font-headline-2: 'Changa One', cursive;
$font-mono: 'Cutive Mono', monospace;
$font-dosis: 'Dosis', sans-serif;

:root {
  --pine: #1F271B;
  --teal: #21807f;
  --green: #6ba478;
  --blue: #145C9E;
  --blue-light: #3f6f9d;
  --blue-dark: #061c30;
  --purple: #665f9a;
  --beige-dark: #CBB9A8;
  --beige-light: #DCC7BE;
  --yellow: #ffda6c;
  --p-beige: #dfdebf;
  --p-green: #c6d7d1;
  --p-yellow: #ffdc3f;
  --p-white: #feffff;
  --p-pink: #f2cdc4;
  --light-green: #7BC0A8;
  --p-beige-50: rgb(223, 222, 191, 0.5);
  --p-green-50: rgb(198, 215, 209, 0.5);
  --p-yellow-50: rgb(255, 220, 63, 0.5);
  --p-white-50: rgb(254, 255, 255, 0.5);
  --p-pink-50: rgb(242, 205, 196, 0.5);
  --light-green-50: rgb(123, 192, 168, 0.5);
}

body {
  background-color: white;
  font-family: $font-dosis;
  font-size: 24px;
  font-weight: 300;
  line-height: 1.5;
  color: var(--blue-dark);

  &.no-scroll {
    overflow-y: hidden;
  }
}

body * {
  box-sizing: border-box;
}

p {
  font-weight: 300;
  max-width: 800px;
  line-height: 1.5;

  @media only screen and (max-width: 600px) {
    max-width: 100%;
  }

  b {
    font-weight: 600;
  }
}

h2 {
  font-family: $font-headline;
  font-weight: 600;
  font-size: 42px;
  margin: 0 0 56px 0;
}

.section-headline {
  // background-color: lightblue;
  border-radius: 20px;
  padding: 0 16px;

  &--yellow {
    background-color: var(--p-yellow);
  }

  &--gradient-pink {
    background: rgb(242,205,196);
    background: linear-gradient(90deg, rgba(242,205,196,1) 35%, rgba(255,255,255,1) 100%);
  }

  &--gradient-lightgreen {
    background: rgb(123,192,168);
    background: linear-gradient(90deg, rgba(123,192,168,1) 35%, rgba(255,255,255,1) 100%);
  }

  &--gradient-yellow {
    background: rgb(255,220,63);
    background: linear-gradient(90deg, rgba(255,220,63,1) 35%, rgba(255,255,255,1) 100%);
  }

  &--gradient-green {
    background: rgb(198,215,209);
    background: linear-gradient(90deg, rgba(198,215,209,1) 35%, rgba(255,255,255,1) 100%);
  }

  &--gradient-beige {
    background: rgb(223,222,191);
    background: linear-gradient(90deg, rgba(223,222,191,1) 35%, rgba(255,255,255,1) 100%);
  }

  &--gradient-pink-lightgreen {
    background: rgb(242,205,196);
    background: linear-gradient(90deg, rgba(242,205,196,1) 35%, rgba(123,192,168,1) 100%);
  }

  &--gradient-lightgreen-yellow {
    background: rgb(123,192,168);
    background: linear-gradient(90deg, rgba(123,192,168,1) 35%, rgba(255,220,63,1) 100%);
  }

  &--gradient-beige-green {
    background: rgb(223,222,191);
    background: linear-gradient(90deg, rgba(223,222,191,1) 35%, rgba(198,215,209,1) 100%);
  }

  &--gradient-yellow-green {
    background: rgba(255,220,63,1);
    background: linear-gradient(90deg, rgba(255,220,63,1) 35%, rgba(198,215,209,1) 100%);
  }
}

.module {
  margin-left: 80px;
  margin-right: 80px;

  @media only screen and (max-width: 800px) {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.calendly {
  display: flex;
  justify-content: center;
  margin-top: 120px;
}

.calendly-button {
  background-color: var(--light-green);
  min-width: 100px;
  // height: 50px;
  border-radius: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 7px 0px;
  text-decoration: none;
  color: black;
  font-weight: 300;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 10px;
  transition: 400ms;
  font-size: 42px;

  @media only screen and (max-width: 600px) {
    font-size: 26px;
  }

  &:hover {
    text-decoration-color: var(--p-white);
    color: var(--p-white);
  }
}

.text {
  &--centered {
    // margin-left: auto;
    // margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.u-white {
  color: white;
}

.u-black {
  color: black;
}

.footnote {
  font-size: 14px;

  &-link {
    color: inherit;
    text-decoration: underline;
  }
}

.text-citation {
  color: black;
  text-decoration: none;
}

main {
  // scroll-snap-type: y mandatory;
  scroll-snap-type: none;
  height: 100vh;
  overflow-y: scroll;

  @media only screen and (max-width: 600px) {
    scroll-snap-type: none;
  }
}

section {
  padding: 0 0 120px 0;
  scroll-snap-align: start;
  // min-height: 100vh;
  display: flex;
  width: 100%;

  &.section-intro {
    background-color: var(--p-white);
    min-height: 100vh;
    padding: 120px 40px 120px 40px;
  }

  // &.section-animation {
  //   padding: 100px 0 100px 0;
  //   background-color: white;
  //   font-size: 18px;
  //   min-height: 60vh;
  //   margin-top: 100px;
  //   position: relative;
  // }

  &.section-not-alone {
    scroll-margin: 100px;
    // background-color: var(--p-yellow-50);
  }

  &.section-overview {
    background-color: var(--p-white);
  }

  &.section-steps {
    // background-color: var(--p-pink-50);
  }

  &.section-contact-person {
  }

  &.section-calendar {
    scroll-margin: 100px;
    // background-color: var(--p-green-50);
  }

  &.section-partners {
    // background-color: #fcfcce;
    background-color: white;
  }

  &.section-blog-entries {
    // background-color: var(--green);
  }

  &.section-newsletter {
    // background-color: var(--p-beige-50);
  }
}

.u-flex {
  display: flex;
}

.u-justify-center {
  justify-content: center;
}

.u-justify-between{
  justify-content: space-between;
}

.u-align-center {
  align-items: center;
}

.content {
  // display: flex;
  max-width: $max-width;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  width: 100%;

  @media only screen and (max-width: 600px) {
    padding-left: 12px;
    padding-right: 12px;
  }
}

.content-white-inside {
  background-color: white;
  border-radius: 12px;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;

  @media only screen and (max-width: 600px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.header {
  background-color: var(--light-green);
  color: black;
  scroll-snap-align: start;
  position: fixed;
  width: 100%;
  z-index: 10;
  box-shadow: 0 0 4px 0px rgb(0 0 0 / 40%);

  &-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
  }

  &-logo {
    font-family: $font-headline;
    font-size: 48px;
    font-weight: 700;
    padding: 10px 10px 10px 0;
    height: 100%;
    max-width: 120px;
  }

  &-logo-link {
    display: block;
  }

  &-logo-image {
    display: block;
    width: 100%;
    height: 100%;
  }

  &-links {
    @media only screen and (max-width: 600px) {
      display: none;
    }
  }

  &-link {
    color: black;
    text-decoration: none;
    margin-right: 8px;
    transition: opacity 200ms;

    &:hover {
      cursor: pointer;
      opacity: 0.6;;
    }
  }

  &-menu-button {
    display: none;
    cursor: pointer;

    @media only screen and (max-width: 600px) {
      display: block;
    }

    .header-menu-open {
      display: none;
    }

    .header-menu-closed {
      display: flex;
    }

    &.is-open {
      .header-menu-open {
        display: flex;
      }

      .header-menu-closed {
        display: none;
      }
    }
  }

  &-icon {
    height: 40px;
    width: 40px;
    color: black;

    &--menu {
      height: 35px;
      width: 35px;
    }
  }
}

.mobile-menu {
  display: none;
  background-color: var(--light-green);
  color: black;
  transform: translateX(100%);
  transition: 200ms;
  position: fixed;
  width: 100%;
  z-index: 9;

  @media only screen and (max-width: 600px) {
    display: block;
  }

  &.is-open {
    transform: translateX(0);
  }

  &-content {
    min-height: 100vh;
  }

  &-links {
    display: flex;
    flex-direction: column;
    padding-top: 92px;
  }

  &-link {
    color: black;
    text-decoration: none;
    margin-right: 8px;
    transition: opacity 200ms;

    &:hover {
      cursor: pointer;
      opacity: 0.6;;
    }
  }
}

.fixed-box {
  background-color: var(--light-green);
  min-width: 100px;
  height: 50px;
  position: fixed;
  bottom: 18px;
  right: 24px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 7px 0px;
  text-decoration: none;
  color: black;
  z-index: 2;
  padding-left: 12px;
  padding-right: 12px;
  pointer-events: none;
  opacity: 0;
  transition: opacity 400ms;

  &.is-active {
    pointer-events: all;
    opacity: 1;
  }
}

.intro {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }

  &-left {
    width: 70%;
    padding-right: 40px;
    @media only screen and (max-width: 600px) {
      width: 100%;
      padding-right: 0;
    }

    &-top {
      font-family: $font-headline;
      font-size: 60px;
      font-weight: 700;

      @media only screen and (max-width: 600px) {
        font-size: 46px;
        line-height: 1.2;
      }
    }

    &-center {
      font-family: $font-dosis;
      font-size: 32px;
      font-weight: 400;

      @media only screen and (max-width: 600px) {
        margin-top: 12px;
        font-size: 28px;
      }
    }

    &-bottom {
      font-family: $font-dosis;
      font-size: 24px;
      font-weight: 200;
      margin-top: 48px;
    }
  }
  &-right {
    width: 30%;
    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }

  &-bubbles {
    height: 250px;
    position: relative;

    @media only screen and (max-width: 600px) {
      margin-top: 80px;
      height: 100px;
    }
  }

  &-front-bubble {
    display: flex;
    // justify-content: flex-end;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    animation-name: bubbleanimationfront;
    animation-duration: 2s;
    animation-delay: 1s;
    animation-fill-mode: forwards;

    @media only screen and (max-width: 600px) {
      justify-content: center;
    }
  }

  @keyframes bubbleanimationfront {
    from {transform: translate(-100%, -100%); opacity: 0;}
    to {transform: translate(0, 0); opacity: 1;}
  }

  &-back-bubble {
    display: flex;
    // justify-content: flex-end;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    animation-name: bubbleanimationback;
    animation-duration: 2s;
    animation-delay: 1s;
    animation-fill-mode: forwards;

    @media only screen and (max-width: 600px) {
      justify-content: center;
    }
  }

  @keyframes bubbleanimationback {
    from {transform: translate(100%, 100%); opacity: 0;}
    to {transform: translate(0, 0); opacity: 1;}
  }

  &-front-bubble-svg {
    height: 100%;
  }

  &-back-bubble-svg {
    height: 100%;
  }
}

.scroll-link {
  text-decoration: underline;
  cursor: pointer;
  font-weight: 300;
  transition: 200ms;

  &--more {
    text-decoration-color: var(--p-pink);

    &:hover {
      text-decoration-color: var(--light-green);
      color: var(--light-green);
    }
  }

  &--meeting {
    // text-decoration-color: var(--p-beige);
    background-color: var(--light-green);
    min-width: 100px;
    height: 50px;
    border-radius: 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 7px 0px;
    text-decoration: none;
    color: black;
    font-weight: 300;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 10px;
    transition: 400ms;

    &:hover {
      text-decoration-color: var(--p-white);
      color: var(--p-white);
    }

    @media only screen and (max-width: 864px) {
      margin-left: 0;
      margin-top: 10px;
    }
  }
}

.animation {
  min-height: 40px;

  &-input {
    background-color: white;
    display: flex;
    align-items: center;
    padding: 8px 16px 8px 24px;
    font-size: 36px;
    margin-bottom: 54px;
    border-radius: 8px;
    // pointer-events: none;
    cursor: default;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 32px;
    // box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.35);
    border: 2px solid #ccc;
    background-color: #fbf9f9;

    @media only screen and (max-width: 600px) {
      font-size: 18px;
    }
  }

  &-input-icon {
    margin-right: 24px;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-input-inner {
    cursor: default;
  }

  .line-1 {
    font-family: $font-mono;
    position: relative;
    // top: 50%;  
    width: 100%;
    margin: 0 auto;
    border-right: 2px solid rgba(255,255,255,.75);
    font-size: 32px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    transform: translateY(-50%);

    @media only screen and (max-width: 600px) {
      font-size: 16px;
    }
  }

  .writing-animation {
    animation: typewriter 4s steps(44) 1s infinite normal both,
    blinkTextCursor 500ms steps(44) infinite normal;
  }
  @keyframes typewriter {
    from{width: 0;}
    to{width: 24em;}
  }
  @keyframes blinkTextCursor {
    from{border-right-color: rgba(255,255,255,.75);}
    to{border-right-color: transparent;}
  }

  &-keyboard-wrapper {
    position: relative;
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    @media only screen and (max-width: 500px) {
      width: 200px;
    }
  }

  &-keyboard-image {
    // width: 100%;
    // height: 100%;
    width: 100%;
    height: auto;
    // margin-left: auto;
    // margin-right: auto;
  }

  &-hand-wrapper {
    position: absolute;

    &--left {
      width: 200px;
      left: 0;
      top: 50px;
      animation-name: lefthand;
      animation-duration: 2s;
      animation-iteration-count: 5;
      @media only screen and (max-width: 500px) {
        width: 100px;
        top: 25px;
      }
    }

    &--right {
      width: 150px;
      top: 50px;
      right: 0;
      animation-name: righthand;
      animation-duration: 1.5s;
      animation-iteration-count: 6;
      @media only screen and (max-width: 500px) {
        width: 75px;
        top: 25px;
      }
    }

    @keyframes righthand {
      0%   {transform: translate(0px, 0px);}
      25%  {transform: translate(15px, 50px);}
      50%  {transform: translate(20px, 0px);}
      75%  {transform: translate(10px, 10px);}
      100% {transform: translate(0px, 0px);}
    }

    @keyframes lefthand {
      0%   {transform: translate(0px, 0px);}
      25%  {transform: translate(-10px, 15px);}
      50%  {transform: translate(-5px, -20px);}
      75%  {transform: translate(-5px, -10px);}
      100% {transform: translate(0px, 0px);}
    }

    // @keyframes righthand {
    //   0%   {transform: translate(0px 0px);}
    //   25%  {transform: translate(15px 5px);}
    //   50%  {transform: translate(20px 0px);}
    //   75%  {transform: translate(10px 10px);}
    //   100% {transform: translate(0px 0px);}
    // }
  }

  &-hand-image {
    width: 100%;
    height: auto;

    &--left {}
    &--right {}
  }

  &-down-arrow-area {
    position: absolute;
    bottom: 15px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &-down-arrow-wrapper {
    // position: absolute;
    // bottom: 15px;
    // width: 100%;
    // display: flex;
    // justify-content: center;
    animation: shake 8s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
    // animation-delay: 8000ms;
    animation-iteration-count: infinite;
  }

  &-down-arrow {
    width: 24px;
    height: 24px;
    cursor: pointer;
    // transform: rotate(90deg) translate3d(-50%, 0, 0);
    transform: rotate(90deg) translateX(-50%);
    opacity: 0.75;
    // animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    // // transform: translate3d(0, 0, 0);
    // backface-visibility: hidden;
    // perspective: 1000px;


    @keyframes shake {
      91%, 99% {
        transform: translate3d(0, -1px, 0);
      }
      92%, 98% {
        transform: translate3d(0, 2px, 0);
      }
      93%, 95%, 97% {
        transform: translate3d(0, -4px, 0);
      }
      94%, 96% {
        transform: translate3d(0, 4px, 0);
      }
    }
  }

  &-down-arrow-icon {
    width: 100%;
    height: 100%;
  }
}

.facts {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }

  &-fact {
    width: 33.333%;
    background-color: white;
    margin: 20px;
    border-radius: 12px;
    padding: 18px;
    // box-shadow: rgb(0 0 0 / 10%) 0px 0px 7px 0px;

    @media only screen and (max-width: 800px) {
      width: 100%;
      margin: 0 0 24px 0;
    }
  }

  &-fact-content {
    //
  }

  &-image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 180px;
  }

  &-image {
    // max-width: 66%;
    // max-height: 150px;
    width: 100%;
    height: 100%;
    object-fit: contain;

    &1 {
      // width: 60%;
    }

    &2 {
      // width: 85%;
    }

    &3 {
      // width: 50%;
    }
  }

  &-headline {
    display: flex;
    justify-content: center;
    font-weight: 700;
    font-size: 24px;
    margin-top: 12px;
    padding: 0 12px;
  }

  &-description {
    display: flex;
    justify-content: center;
    padding: 0 12px;
    text-align: center;
  }
}

.steps {
  display: flex;
  // justify-content: space-between;
  justify-content: space-around;
  align-items: stretch;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }

  &-step {
    width: 33.333%;
    padding: 12px;
    background-color: white;
    border-radius: 12px;

    @media only screen and (max-width: 600px) {
      width: 100%;
      padding: 12px 0;
    }

    &-content {
      background-color: white;
      border-radius: 12px;
      padding: 36px 0;
      height: 100%;
    }
  }

  &-image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 180px;
  }

  &-image {
    // width: 66%;
    // max-height: 200px;
    width: 100%;
    height: 100%;
    object-fit: contain;

    &1 {}
    &2 {}
    &3 {}
  }

  &-title {
    display: flex;
    justify-content: center;
    margin-top: 36px;
    font-weight: 500;
    padding: 0 12px;
  }
}

.slider {
  width:100%;
  // height: 500px;

  .swiper-slide {
    // height: 500px;
  }

  &-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-arrow {
    height: 46px;
    width: 46px;
    cursor: pointer;
    color: var(--light-green);

    &--left {
      transform: rotate(180deg);
    }
  }

  &-arrow-icon {
    width: 100%;
    height: 100%;
    // height: 46px;
    // width: 46px;
    display: block;
  }

  &-pagination.swiper-pagination {
    display: flex;
    position: relative;
    bottom: 0;
  }

  &-bullet {
    width: 10px;
    height: 10px;
    background-color: var(--light-green-50);
    display: block;
    cursor: pointer;
    margin-left: 4px;
    margin-right: 4px;

    &--active {
      background-color: var(--light-green);
      cursor: default;
    }
  }
}

.contact-person {
  display: flex;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }

  &-portrait {
    width: 50%;

    @media only screen and (max-width: 600px) {
      width: 100%;
    }

    img {
      @media only screen and (max-width: 600px) {
        max-height: 250px;
      }
    }
  }

  &-text {
    font-size: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    padding-left: 20px;
    padding-right: 4px;

    @media only screen and (max-width: 600px) {
      width: 100%;
      font-size: 18px;
      // padding: 0 60px;
      margin-bottom: 18px;
    }
  }

  &-quote {
    font-style: italic;
  }

  &-info {
    margin-top: 18px;
  }
  &-name {
    font-weight: 700;
    display: inline;
  }
  &-age {
    display: inline;
  }
  &-occupation {
    display: inline;
  }
  &-diagnosis {}

  &-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    padding-left: 4px;
    padding-right: 4px;
  }
}

.others {
  &-content {
    margin-top: 80px;

    @media only screen and (max-width: 800px) {
      margin-top: 56px;
    }
  }

  &-row {
    display: flex;
    margin-bottom: 56px;
    padding: 0 4px 0 4px;

    &--odd {
      justify-content: start;

      .others-text-wrapper {
        margin-left: 42px;
      }
    }

    &--even {
      justify-content: end;

      .others-text-wrapper {
        margin-right: 42px;
      }
    }

    @media only screen and (max-width: 800px) {
      flex-direction: column;

      &.others-row--even {
        flex-direction: column-reverse;
      }

      .others-text-wrapper {
        margin-left: 0;
        margin-right: 0;
        margin-top: 36px;
      }
    }
  }

  &-image-wrapper {
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
    max-height: 300px;
    max-width: 300px;

    @media only screen and (max-width: 800px) {
      max-height: 150px;
      max-width: 150px;
    }

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  &-name {
    font-weight: 500;
    margin-top: 12px;
  }
}

.partners {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  margin-top: 56px;

  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }

  &-item {
    width: 33.333%;
    padding: 36px;
    margin-bottom: 28px;

    @media only screen and (max-width: 800px) {
      width: 100%;
      padding: 0 24px;
      margin-bottom: 64px;
    }
  }

  &-card {
    text-decoration: none;
    // background-color: white;
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    // border-radius: 12px;
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 7px 0px;
    transition: box-shadow 200ms;
    height: 100%;

    &:hover {
      cursor: pointer;
    }
  }

  &-image-wrapper {
    // border-bottom-left-radius: 4px;
    // border-bottom-right-radius: 4px;
    // overflow: hidden;
  }

  &-image {
    display: block;
    height: auto;
    width: 100%;
    object-fit: cover;
  }

  &-title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 12px;
    font-weight: 500;
  }
}

.blog-entries {
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }

  &-item {
    width: 25%;
    padding: 12px;

    @media only screen and (max-width: 800px) {
      width: 100%;
      padding: 12px 0;
    }
  }

  &-card {
    text-decoration: none;
    background-color: white;
    color: inherit;
    display: block;
    overflow: hidden;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 7px 0px;
    transition: box-shadow 200ms;
    height: 100%;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 7px 0px;
    }
  }

  &-image-wrapper {
    // border-bottom-left-radius: 4px;
    // border-bottom-right-radius: 4px;
    // overflow: hidden;
  }

  &-image {
    display: block;
    height: auto;
    width: 100%;
    object-fit: cover;
  }

  &-title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 12px;
    font-weight: 500;
  }
}

.newsletter {
  &-signup {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;

    &-form {
      display: flex;
      justify-content: center;
      align-items: center;

      @media only screen and (max-width: 600px) {
        flex-direction: column;
      }
    }

    &-mail {
      border: 2px solid #ddd;
      background-color: rgb(244, 244, 244);
      padding: 20px 50px;
      border-radius: 12px;
      margin-right: 12px;
      outline: none;

      &:focus {
        border: 2px solid #6c6b6b;
      }

      @media only screen and (max-width: 600px) {
        margin-right: 12px;
        margin-bottom: 12px;
      }
    }

    &-submit {
      border: 2px solid #bce1f7;
      padding: 20px 50px;
      background-color: #bce1f7;
      border-radius: 12px;
      transition: border-color 200ms, background-color 200ms;

      &:hover {
        cursor: pointer;
        border: 2px solid #abcadc;
        background-color: #abcadc;
      }
    }
  }

  &-image-wrapper {
    margin-top: 28px;
  }

  &-image {
    display: block;
    width: 100%;
    height: auto;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }

  &-widget-wrapper {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 10px;
  }
}

.page-block {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 36px;

  b {
    font-weight: 600;
  }

  &-left {
    width: 33.333%;
    padding-right: 18px;

    @media only screen and (max-width: 600px) {
      width: 100%;
      padding-right: 0;
    }
  }

  &-right {
    width: 66.666%;

    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }

  &-image {
    width: 100%;
    height: auto;
    display: block;
  }
}

.footer {
  background-color: var(--pine);
  color: white;
  padding-top: 80px;
  padding-bottom: 60px;
  font-family: $font-dosis;
  scroll-snap-align: start;

  &-columns {
    display: flex;
    justify-content: space-between;
    padding-bottom: 24px;

    @media only screen and (max-width: 600px) {
      flex-direction: column;
    }
  }

  &-column {
    display: flex;
    flex-direction: column;
    margin-right: 26px;

    &--last {
      margin-right: 0;
    }

    @media only screen and (max-width: 600px) {
      margin-right: 0;
    }
  }

  &-title {
    padding-bottom: 8px;
    font-weight: bold;

    &--1.footer-title {
      @media only screen and (max-width: 600px) {
        margin-top: 0;
      }
    }

    @media only screen and (max-width: 600px) {
      margin-top: 36px;
    }
  }

  &-link {
    color: inherit;
    text-decoration: none;
    padding-bottom: 8px;
    transition: opacity 200ms;

    &:hover {
      cursor: pointer;
      opacity: 0.6;;
    }
  }

  &-row {
    &-link {
      text-decoration: none;
      color: inherit;
      transition: opacity 200ms;

      &:hover {
        cursor: pointer;
        opacity: 0.6;;
      }
    }
  }

  &-bottom {
    border-top: 1px solid white;
    padding-top: 24px;
  }
}